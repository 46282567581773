import React, { FC } from 'react';
import { Link } from 'gatsby';

type LinkType = {
    name?: string;
    udi?: string;
    url: string;
    icon?: string;
    target?: string;
    queryString?: string;
  };

type SubnavigationItemType = {
    label: string;
    url: LinkType[];
  };

type SubnavigationType = {
    structure: string;
    properties: SubnavigationItemType;
  };

export interface SubnavigationLinksProps {
    classPrefix: 'main' | 'mobile';
    subnavigationLinks: SubnavigationType[];
  }

const SubnavigationLinks: FC<SubnavigationLinksProps> = ({ subnavigationLinks, classPrefix }) => {
    if (!subnavigationLinks.length) return null;
    return (
            <ul className={`${classPrefix}-nav__subnav-list`}>
              {subnavigationLinks?.map(({ properties: { label: subnavLabel, url: subnavUrl } }) => (
                <li
                  className={`${classPrefix}-nav__subnav-label`}
                  key={`subnav-item-${subnavLabel}`}
                >
                  <div className={`${classPrefix}-nav__subnav-title`}>
                      {subnavLabel}
                  </div>
                  <div className={`${classPrefix}-nav__subnav-urls`}>
                    {subnavUrl?.map((item, i) => (
                      <li
                          className={`${classPrefix}-nav__subnav-link`}
                          key={`subnav-item-${i}`}
                      >
                        <Link
                          aria-label={item?.name}
                          to={`${item?.url}`}
                          className={`${classPrefix}-nav__subnav-link link`}
                        >
                          {item?.name}
                        </Link>
                      </li>
                    ))}
                  </div>
                </li>
              ))}
            </ul>
    );
  };

export default SubnavigationLinks;
