import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { extractUrlFromMultiPicker } from 'gatsby-theme-husky/src/utils/umbracoControlsUtils';

import { NavMenuProps } from 'gatsby-theme-husky/src/components/Header/HeaderNav/NavMenu/models';
import SubnavigationLinks from './SubnavigationLinks';

import './NavMenuOverride.scss';

const NavMenu: FC<NavMenuProps> = ({ classPrefix, navigation }) => {
  return (
    <ul className={`${classPrefix}-nav__navigation-list`} data-testid="navigation-list">
      {navigation.map(({ properties: { label, url, subnavigationLinks } }) => (
        <li
          className={`${classPrefix}-nav__link-container`}
          key={`${classPrefix}-nav-item-${label}`}
          data-testid="navigation-item"
        >
          <Link
            aria-label={label}
            to={`${extractUrlFromMultiPicker(url)}`}
            className={classNames(`${classPrefix}-nav__link link`, {
              'mobile-nav__subnav-arrow': subnavigationLinks?.length,
            })}
            data-testid="navigation-link"
            activeClassName="active"
            partiallyActive
          >
            {label}
          </Link>
          {subnavigationLinks?.length ? <i className="fa fa-angle-down" /> : null}
          <SubnavigationLinks subnavigationLinks={subnavigationLinks} classPrefix={classPrefix} />
        </li>
      ))}
    </ul>
  );
};

export default NavMenu;
